import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Div100vh from '../../components/atoms/Div100vh'
import styles from './style.module.scss'
import useMode, { useResentMode, useTrainingMode } from '../hooks/useMode'
import StepperHeader from '../../components/organisms/StepperHeader'
import AppHeader from '../../components/organisms/AppHeader'
import useTenant from '../hooks/useTenant'
import { useIsOpa } from '../hooks/useOrg'
import Typography from '../../components/atoms/Typography'
import { prevText, nextText, titleText } from './messages'
import Button from '../../components/atoms/Button'
import Popup, { PopupItem } from '../../components/molecules/Popup'
import {
  preCameraPath,
  registerConfirmationPath,
  topWithClearDataPath,
} from '../../routes/paths'
import useAppHistory from '../../utils/useAppHistory'
import {
  clearAPIStatus as clearTenantRegisterAPIStatus,
  getTenantRegisterNumbers,
  postTenantRegisterAssignment,
} from '../../features/network/tenantRegisterSlice'
import { RootState } from '../../store'
import { TenantRegisterCategoryEnum } from '../../features/services/api'
import ErrorModal from '../../components/organisms/ErrorModal'
import { AppError } from '../../AppTypes'
import {
  clearAPIStatus as clearRegisterAPIStatus,
  postAppSalesdateRegisters,
} from '../../features/register/registerSlice'

type RegisterNumberProps = {}
const RegisterNumber: React.StyledFC<RegisterNumberProps> = () => {
  const tenant = useTenant()
  const isResent = useResentMode()
  const mode = useMode()
  const isTraining = useTrainingMode()
  const history = useAppHistory()
  const isOPA = useIsOpa()
  const dispatch = useDispatch()
  const [selectedRegister, setSelectedRegister] = useState<string>('')
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)

  const {
    tenantRegisters,
    alreadyReportedTenantRegisters,
    getError,
    postError,
  } = useSelector((state: RootState) => state.tenantRegister)
  const { registerCount } = useSelector((state: RootState) => state.register)
  const registerError = useSelector((state: RootState) => state.register.error)

  useEffect(() => {
    dispatch(getTenantRegisterNumbers(tenant.id))
  }, [])

  const handleSelectRegister = (value: string): void => {
    setSelectedRegister(value)
    setIsButtonDisabled(false)
  }

  const handleClickNext = async (): Promise<void> => {
    if (!selectedRegister) {
      return
    }

    if (isTraining) {
      history.push(preCameraPath)
    }

    // NOTE: 複数レジの場合は、sales_date_registersを先に作成する
    if (registerCount !== 1) {
      const actionResult = await dispatch(postAppSalesdateRegisters())
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      if (!postAppSalesdateRegisters.fulfilled.match(actionResult)) {
        return
      }
    }

    const actionResult = await dispatch(
      postTenantRegisterAssignment(selectedRegister)
    )
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    if (postTenantRegisterAssignment.fulfilled.match(actionResult)) {
      history.push(preCameraPath)
    }
  }

  const handleClickPrev = (): void => {
    history.push(registerConfirmationPath)
  }

  const handleGetErrorClick = (err: AppError): void => {
    if (err.needReturnTop) {
      history.push(topWithClearDataPath)
      return
    }
    dispatch(clearTenantRegisterAPIStatus())
  }

  const handlePostErrorClick = (err: AppError): void => {
    if (err.needReturnTop) {
      history.push(topWithClearDataPath)
      return
    }
    dispatch(clearRegisterAPIStatus())
    dispatch(clearTenantRegisterAPIStatus())
  }

  const handleRegisterErrorClick = (err: AppError): void => {
    if (err.needReturnTop) {
      history.push(topWithClearDataPath)
      return
    }
    dispatch(clearRegisterAPIStatus())
  }

  const ErrorTypes = {
    get: { error: getError, handler: handleGetErrorClick },
    post: { error: postError, handler: handlePostErrorClick },
    register: { error: registerError, handler: handleRegisterErrorClick },
  }

  const convertTenantRegistersToPopupItems = (): PopupItem[] => {
    const registers = tenantRegisters.filter(
      (tenantRegister) =>
        !alreadyReportedTenantRegisters.some(
          (reportedRegister) => reportedRegister.id === tenantRegister.id
        )
    )
    return registers.map((tenantRegister) => ({
      id: tenantRegister.id,
      value: tenantRegister.id,
      label: tenantRegister.registerNumber,
      isRepresentativeRegister:
        tenantRegister.registerCategory ===
        TenantRegisterCategoryEnum.RepresentativeRegister,
    }))
  }

  return (
    <Div100vh className={styles.container} mode={mode}>
      <StepperHeader current={1} isOpaResent={isOPA && isResent} />
      <AppHeader tenantName={tenant.tenantName} storeName={tenant.storeName} />
      <div className={styles.wrapper}>
        <Typography className={styles.title} color="primary" bold>
          {titleText}
        </Typography>
        <div className={styles.popupWrapper}>
          <Popup
            className={styles.popup}
            items={convertTenantRegistersToPopupItems()}
            onChange={handleSelectRegister}
          />
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            className={styles.button}
            onClick={handleClickNext}
            disabled={isButtonDisabled}
          >
            {nextText}
          </Button>
          {registerCount !== 1 && (
            <Button
              className={styles.button}
              color="white"
              onClick={handleClickPrev}
            >
              {prevText}
            </Button>
          )}
        </div>
      </div>
      {Object.entries(ErrorTypes).map(
        ([key, { error, handler }]) =>
          error && (
            <ErrorModal
              key={key}
              isOpen={Boolean(error)}
              title={error.title}
              description={error.description}
              buttonTitle="OK"
              onClick={(): void => handler(error)}
            />
          )
      )}
    </Div100vh>
  )
}

export default RegisterNumber
