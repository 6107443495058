import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import styles from './style.module.scss'
import Icon from '../../atoms/Icon'

export type PopupItem = {
  id: string
  label: string
  value: string
  isRepresentativeRegister: boolean
}

type PopupProps = {
  items: PopupItem[]
  onChange: (value: string) => void
}

type Props = PopupProps & React.StyledProps
const Popup: React.StyledFC<Props> = ({
  className,
  items,
  onChange,
}: Props) => {
  const [popupOpen, setPopupOpen] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<PopupItem>()

  const togglePopUp = (): void => {
    setPopupOpen(!popupOpen)
  }

  const handleClickOutside = (event: MouseEvent): void => {
    // 枠外をクリックしたらポップアップを閉じる
    const clickedElement = event.target
    if (
      clickedElement instanceof HTMLElement &&
      !clickedElement.closest(`.${styles.window}`)
    ) {
      setPopupOpen(false)
    }
  }

  useEffect(() => {
    if (popupOpen) {
      document.addEventListener('click', handleClickOutside)
    }

    return (): void => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [popupOpen])

  const handleItemClick = (item: PopupItem): void => {
    setSelectedItem(item)
    setPopupOpen(false)
    onChange(item.value)
  }

  return (
    <div className={clsx(styles.root, className)}>
      <button className={styles.button} onClick={togglePopUp}>
        <span>
          {selectedItem ? selectedItem.label : 'レジNoを選択してください'}
        </span>
        <span className={styles.iconWrapper}>
          <Icon
            icon="toggle"
            className={clsx(styles.icon, !popupOpen && styles.rotate)}
          />
        </span>
      </button>
      {popupOpen && (
        <div className={styles.window}>
          <div className={styles.list}>
            {items?.map((item) => (
              <div
                className={styles.item}
                key={item.id}
                onClick={(): void => handleItemClick(item)}
              >
                <p className={styles.label}>{item.label}</p>
                {item.isRepresentativeRegister && (
                  <span className={styles.badge}>代表レジ</span>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default Popup
