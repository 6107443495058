import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { disableLeaveConfirmation, enableLeaveConfirmation } from '../../utils'
import { ApiRequest, AppError, FormAnswerWrapper } from '../../AppTypes'
import { ErrorCodeEnum, Form, FormAnswer, Forms } from '../services/api'
import { bobaService } from '../services'
import { AppThunkConfig } from '../../store'
import {
  ApiError,
  AuthorizationError,
  OutOfServiceError,
} from '../services/errors'
import {
  authenticationErrorAppError,
  authorizationErrorAppError,
  internalServerErrorAppError,
  outOfServiceAppErr,
} from './common'

const service = bobaService()

type State = {
  forms: Form[]
  answers: FormAnswerWrapper[]
} & ApiRequest

const initialState: State = {
  forms: [],
  answers: [],
  apiStatus: 'Initial',
  error: undefined,
}

const handleError = (err: Error): AppError => {
  if (err instanceof ApiError) {
    switch (err.errorCode) {
      case ErrorCodeEnum.InvalidInput:
        return {
          needReturnTop: false,
          description:
            '入力した売上値に不備があります。確認後に修正を行なってください。',
          title: '入力エラー',
        }
      case ErrorCodeEnum.NotFound:
        return {
          needReturnTop: true,
          description:
            '複数端末での利用が確認されましたので、当端末での売上報告は無効となりました。',
          title: '売上報告無効',
        }
      case ErrorCodeEnum.AuthorizationError:
      case ErrorCodeEnum.InvalidToken:
        return authorizationErrorAppError
      case ErrorCodeEnum.InternalServerError:
        return internalServerErrorAppError
      default:
        return {
          needReturnTop: false,
          description:
            '売上データを登録中にエラーが発生しました。時間をおいて再度お試しください。',
          title: '売上登録エラー',
        }
    }
  } else if (err instanceof OutOfServiceError) {
    return outOfServiceAppErr
  } else if (err instanceof AuthorizationError) {
    return authenticationErrorAppError
  }
  return internalServerErrorAppError
}

export const getFormsSlice = createAsyncThunk<Forms, void, AppThunkConfig>(
  'inputForm/get',
  async (v, { getState, rejectWithValue }) => {
    try {
      return await service.getInputForm(getState())
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)

export const createAnswers = createAsyncThunk<
  void,
  FormAnswerWrapper[],
  AppThunkConfig
>('answers/create', async (args, { getState, rejectWithValue }) => {
  try {
    return await service.postInputForm(
      {
        answers: args.map(
          (a): FormAnswer => ({
            formId: a.formId,
            value: a.value,
          })
        ),
      },
      getState()
    )
  } catch (err) {
    return rejectWithValue(handleError(err))
  }
})

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    clearApiRequest: (state): State => {
      return {
        ...state,
        apiStatus: 'Initial',
        error: undefined,
      }
    },
    clearForms: (state): State => {
      return {
        ...state,
        apiStatus: 'Initial',
        error: undefined,
        forms: [],
      }
    },
    clearAnswers: (): State => {
      return initialState
    },
    updateAnswer: (
      state,
      { payload }: PayloadAction<{ formId: string; value: string }>
    ): State => {
      const answers = state.answers.slice()
      const idx = answers.findIndex((a) => a.formId === payload.formId)
      if (idx < 0) {
        return { ...state }
      }
      answers[idx] = {
        ...payload,
        required: answers[idx].required,
      }
      return {
        ...state,
        answers,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFormsSlice.pending, (state) => {
      enableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Progress',
      }
    })
    builder.addCase(
      getFormsSlice.fulfilled,
      (state, { payload }): State => {
        disableLeaveConfirmation()
        let { answers } = state
        if (!answers.length) {
          answers = payload.forms.map((f) => ({
            formId: f.id,
            value: null,
            required: f.required,
          }))
        }
        return {
          ...state,
          answers,
          forms: payload.forms,
          apiStatus: 'Success',
        }
      }
    )
    builder.addCase(
      getFormsSlice.rejected,
      (state, action): State => {
        disableLeaveConfirmation()
        return {
          ...state,
          apiStatus: 'Failure',
          error: action.payload,
        }
      }
    )
    builder.addCase(createAnswers.pending, (state) => {
      enableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Progress',
      }
    })
    builder.addCase(createAnswers.fulfilled, (state) => {
      disableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Success',
      }
    })
    builder.addCase(createAnswers.rejected, (state, action) => {
      disableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Failure',
        error: action.payload,
      }
    })
  },
})

export const {
  updateAnswer,
  clearAnswers,
  clearApiRequest,
  clearForms,
} = formSlice.actions
export default formSlice
