import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { bobaService } from '../services'
import {
  ErrorCodeEnum,
  NotificationsBySalesDate,
  SalesDatesWithUnreadNotificationsCount,
  TenantNotificationBySalesDate,
} from '../services/api'
import { AppThunkConfig } from '../../store'
import { ApiRequest, AppError } from '../../AppTypes'
import {
  OutOfServiceError,
  AuthorizationError,
  ApiError,
} from '../services/errors'
import {
  outOfServiceAppErr,
  authenticationErrorAppError,
  internalServerErrorAppError,
  authorizationErrorAppError,
} from './common'
import { disableLeaveConfirmation, enableLeaveConfirmation } from '../../utils'

const service = bobaService()

type NotificationState = {
  salesDatesWithUnreadNotificationsCount: SalesDatesWithUnreadNotificationsCount
  enableOpenModal: boolean
  tenantNotificationBySalesDates: TenantNotificationBySalesDate[]
  selectedSalesDateId?: string
  notificationsBySalesDate?: NotificationsBySalesDate
} & ApiRequest

const initialState: NotificationState = {
  apiStatus: 'Initial',
  salesDatesWithUnreadNotificationsCount: { count: 0 },
  enableOpenModal: false,
  tenantNotificationBySalesDates: [],
  selectedSalesDateId: undefined,
  notificationsBySalesDate: undefined,
  error: undefined,
}

const handleError = (err: Error): AppError => {
  // FIX: 文言もカスタムする
  if (err instanceof ApiError) {
    switch (err.errorCode) {
      case ErrorCodeEnum.AuthorizationError:
        return authorizationErrorAppError
      default:
        return internalServerErrorAppError
    }
  }
  if (err instanceof OutOfServiceError) {
    return outOfServiceAppErr
  }
  if (err instanceof AuthorizationError) {
    return authenticationErrorAppError
  }
  return internalServerErrorAppError
}

export const getSalesDatesWithUnreadNotificationsCount = createAsyncThunk<
  SalesDatesWithUnreadNotificationsCount,
  string,
  AppThunkConfig
>(
  'tenants/{tenant_id}/salesdates/with-unread-notifications/count',
  async (args, { getState, rejectWithValue }) => {
    try {
      return await service.getSalesDatesWithUnreadNotificationsCount(
        args,
        getState()
      )
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)

export const getTenantNotificationsBySalesDate = createAsyncThunk<
  TenantNotificationBySalesDate[],
  string,
  AppThunkConfig
>(
  'tenants/{tenant_id}/notifications-by-sales-date',
  async (args, { getState, rejectWithValue }) => {
    try {
      return await service.getTenantNotificationsBySalesDate(args, getState())
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)

export const getNotificationsBySalesDate = createAsyncThunk<
  NotificationsBySalesDate,
  { tenantId: string; salesDateId: string },
  AppThunkConfig
>(
  'tenants/{tenant_id}/salesdates/{sales_date_id}/notifications',
  async (args, { getState, rejectWithValue }) => {
    try {
      return await service.getNotificationsBySalesDate(
        args.tenantId,
        args.salesDateId,
        getState()
      )
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)

export const patchNotifications = createAsyncThunk<
  void,
  { tenantId: string; salesDateId: string; notificationIds: string[] },
  AppThunkConfig
>(
  'patch/tenants/{tenant_id}/salesdates/{sales_date_id}/notifications',
  async (args, { getState, rejectWithValue }) => {
    try {
      return await service.patchNotifications(
        args.tenantId,
        args.salesDateId,
        args.notificationIds,
        getState()
      )
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    clearNotifications: (): NotificationState => {
      return {
        ...initialState,
      }
    },
    clearAPIStatus: (state): NotificationState => {
      return {
        ...state,
        apiStatus: 'Initial',
        error: undefined,
      }
    },
    enableOpenModal: (state): NotificationState => {
      return {
        ...state,
        enableOpenModal: true,
      }
    },
    disableOpenModal: (state): NotificationState => {
      return {
        ...state,
        enableOpenModal: false,
      }
    },
    setSelectedSalesDateId: (state, action): NotificationState => {
      const selectedSalesDateId = action.payload
      return {
        ...state,
        selectedSalesDateId,
      }
    },
    clearSelectedSalesDateId: (state): NotificationState => {
      return {
        ...state,
        selectedSalesDateId: undefined,
      }
    },
    clearNotificationsBySalesDate: (state): NotificationState => {
      return {
        ...state,
        notificationsBySalesDate: undefined,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getSalesDatesWithUnreadNotificationsCount.pending,
      (state) => {
        enableLeaveConfirmation()
        return {
          ...state,
          apiStatus: 'Progress',
        }
      }
    )
    builder.addCase(
      getSalesDatesWithUnreadNotificationsCount.fulfilled,
      (state, action) => {
        disableLeaveConfirmation()
        const salesDatesWithUnreadNotificationsCount = action.payload
        return {
          ...state,
          apiStatus: 'Success',
          salesDatesWithUnreadNotificationsCount,
        }
      }
    )
    builder.addCase(
      getSalesDatesWithUnreadNotificationsCount.rejected,
      (state) => {
        disableLeaveConfirmation()
        // NOTE: CountのAPIが失敗しても報告できる状態を保ちたいのでエラーとして扱わず、Stateを更新しない
        return {
          ...state,
        }
      }
    )
    builder.addCase(getTenantNotificationsBySalesDate.pending, (state) => {
      enableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Progress',
      }
    })
    builder.addCase(
      getTenantNotificationsBySalesDate.fulfilled,
      (state, action) => {
        disableLeaveConfirmation()
        const tenantNotificationBySalesDates = action.payload
        return {
          ...state,
          apiStatus: 'Success',
          tenantNotificationBySalesDates,
        }
      }
    )
    builder.addCase(
      getTenantNotificationsBySalesDate.rejected,
      (state, action) => {
        disableLeaveConfirmation()
        return {
          ...state,
          apiStatus: 'Failure',
          error: action.payload,
        }
      }
    )
    builder.addCase(getNotificationsBySalesDate.pending, (state) => {
      enableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Progress',
      }
    })
    builder.addCase(getNotificationsBySalesDate.fulfilled, (state, action) => {
      disableLeaveConfirmation()
      const notificationsBySalesDate = action.payload
      return {
        ...state,
        apiStatus: 'Success',
        notificationsBySalesDate,
      }
    })
    builder.addCase(getNotificationsBySalesDate.rejected, (state, action) => {
      disableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Failure',
        error: action.payload,
      }
    })
    builder.addCase(patchNotifications.pending, (state) => {
      enableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Progress',
      }
    })
    builder.addCase(patchNotifications.fulfilled, (state) => {
      disableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Success',
      }
    })
    builder.addCase(patchNotifications.rejected, (state, action) => {
      disableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Failure',
        error: action.payload,
      }
    })
  },
})

export const {
  clearNotifications,
  clearAPIStatus,
  enableOpenModal,
  disableOpenModal,
  setSelectedSalesDateId,
  clearSelectedSalesDateId,
  clearNotificationsBySalesDate,
} = notificationsSlice.actions

export default notificationsSlice
