import React, { ReactElement, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import {
  genRecaptureCameraPath,
  topWithClearDataPath,
  uploadCompletionPath,
  genCameraPath,
  messageConfirmationPath,
} from '../../routes/paths'
import AppHeader from '../../components/organisms/AppHeader'
import Typography from '../../components/atoms/Typography'
import {
  titleText,
  recaptureText,
  upload,
  uploadingProgress,
  actionModalTitle,
  actionModalNegativeButton,
  actionModalPositiveButton,
  checkboxTexts,
} from './messages'
import styles from './style.module.scss'
import CaptureConfirmItem from '../../components/organisms/CaptureConfirmItem'
import Button from '../../components/atoms/Button'
import Space from '../../components/atoms/Space'
import {
  uploadImage,
  clearUploadImage,
  uploadResentImage,
} from '../../features/network/uploadImageSlice'
import FullSize from '../../components/templates/FullSize'
import useTenant from '../hooks/useTenant'
import useExpire from '../hooks/useExpire'
import AddRectangle from '../../components/molecules/AddRectangle'
import ErrorModal from '../../components/organisms/ErrorModal'
import { AppError } from '../../AppTypes'
import { deleteImage } from '../../features/camera/cameraSlice'
import ActionModal from '../../components/organisms/ActionModal'
import Checkbox from '../../components/atoms/Checkbox'
import useMode, { useResentMode, useTrainingMode } from '../hooks/useMode'
import useDate from '../hooks/useDate'
import { useIsOpa } from '../hooks/useOrg'
import LoadingModal from '../../components/organisms/LoadingModal'
import StepperHeader from '../../components/organisms/StepperHeader'
import useAppHistory from '../../utils/useAppHistory'

function CameraConfirmAll(): ReactElement {
  const [deleteTargetIndex, setDeleteTargetIndex] = useState<null | number>(
    null
  )
  const { images } = useSelector((state: RootState) => state.camera)
  const { apiStatus, error } = useSelector(
    (state: RootState) => state.uploadImage
  )
  const { registerCount } = useSelector((state: RootState) => state.register)
  const history = useAppHistory()
  const dispatch = useDispatch()
  const mode = useMode()
  const isTraining = useTrainingMode()
  const isResent = useResentMode()
  const isOPA = useIsOpa()
  useExpire()
  const tenant = useTenant()
  const processing = useRef(false)
  const [checked, setChecked] = useState(checkboxTexts.map(() => false))
  const handleCheckboxChanged = (idx: number, isChecked: boolean): void => {
    const c = [...checked]
    c[idx] = isChecked
    setChecked(c)
  }
  const handleClick = async (): Promise<void> => {
    if (processing.current) {
      return
    }
    if (isTraining) {
      history.push(uploadCompletionPath)
      return
    }

    processing.current = true

    if (isResent) {
      const actionResult = await dispatch(uploadResentImage(images))
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      if (uploadResentImage.fulfilled.match(actionResult)) {
        history.push(messageConfirmationPath)
      }
      processing.current = false
      return
    }

    const actionResult = await dispatch(uploadImage(images))
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    if (uploadImage.fulfilled.match(actionResult)) {
      history.push(uploadCompletionPath)
    }
    processing.current = false
  }
  const handleErrorClick = (err: AppError): void => {
    if (err.needReturnTop) {
      history.push(topWithClearDataPath)
      return
    }
    dispatch(clearUploadImage())
  }
  const handleAddClick = (): void => {
    if (!images.length) {
      history.push(genCameraPath(0))
      return
    }
    history.push(genRecaptureCameraPath(images.length))
  }
  const handleClickClose = (index: number): void => {
    setDeleteTargetIndex(index)
  }
  return (
    <FullSize className={styles.container} mode={mode} date={useDate()}>
      <StepperHeader
        current={isOPA && !isResent ? 2 : 1}
        isOpaResent={isOPA && isResent}
      />
      <LoadingModal
        isOpen={apiStatus === 'Progress'}
        text={uploadingProgress}
      />
      {error && (
        <ErrorModal
          isOpen
          title={error.title}
          description={error.description}
          buttonTitle="OK"
          onClick={(): void => handleErrorClick(error)}
        />
      )}
      <AppHeader tenantName={tenant.tenantName} storeName={tenant.storeName} />
      <Typography className={styles.title} variant="largeTitle">
        {titleText(registerCount)}
      </Typography>
      <div className={styles.imgWrapper}>
        {images.map((image) => (
          <div key={image.index}>
            <CaptureConfirmItem
              className={styles.img}
              imgSrc={image.data}
              imgAlt={`${image.index + 1}/${images.length} 枚目`}
              buttonText={recaptureText}
              onClick={(): void => {
                history.push(genRecaptureCameraPath(image.index))
              }}
              onClickClose={(): void => {
                handleClickClose(image.index)
              }}
            />
          </div>
        ))}
      </div>
      <AddRectangle className={styles.rectangle} onClick={handleAddClick} />
      <Space orientation="vertical" size="42px" />
      <div className={styles.checkboxGroup}>
        {checkboxTexts.map((txt, index) => (
          <Checkbox
            className={styles.checkbox}
            onChanged={(isChecked: boolean): void => {
              handleCheckboxChanged(index, isChecked)
            }}
            key={`${txt.key}`}
          >
            {txt}
          </Checkbox>
        ))}
      </div>
      <Space orientation="vertical" size="42px" />
      <Button
        variant="contained"
        color="green"
        disabled={images.length === 0 || !checked.every((v) => v)}
        className={styles.btn}
        onClick={handleClick}
      >
        {upload}
      </Button>
      <Space orientation="vertical" size="42px" />
      {deleteTargetIndex !== null && (
        <ActionModal
          isOpen
          title={actionModalTitle(deleteTargetIndex)}
          negativeButtonTitle={actionModalNegativeButton}
          positiveButtonTitle={actionModalPositiveButton}
          onClickNegative={(): void => setDeleteTargetIndex(null)}
          onClickPositive={(): void => {
            dispatch(deleteImage(deleteTargetIndex))
            setDeleteTargetIndex(null)
          }}
        />
      )}
    </FullSize>
  )
}

export default CameraConfirmAll
