import dayjs from 'dayjs'

export const isNetworkEnabled = process.env.REACT_APP_NETWORK_ENABLED
  ? process.env.REACT_APP_NETWORK_ENABLED === 'true'
  : true // default true
export const getBaseUrl = (): string => {
  const baseUrl = process.env.REACT_APP_BASE_URL
  if (baseUrl) {
    return baseUrl
  }
  throw Error(`REACT_APP_BASE_URL must be set`)
}

export const expireTimeoutMilliSec = ((): number => {
  if (process.env.NODE_ENV === 'production') {
    return 1000 * 60 * 30
  }
  // todo: テストのため5分。本来は30分。
  return 1000 * 60 * 5
})()

export const ForceUpdateTimeoutMilSec = ((): number => {
  if (process.env.NODE_ENV === 'production') {
    return 1000 * 60 * 60
  }

  // NOTE: テストのため30秒。本来は1時間。
  return 1000 * 30
})()

export const gridIntervalSize = 88

export const validMinDateAsDayjs = dayjs().add(-12, 'month')

export const errorModalButtonText = `OK`

export const bugsnagApiKey = process.env.REACT_APP_BUGSNAG_API_KEY || ''

export const faqUrl =
  'https://gv-zero-public.notion.site/906587109a524b778311bbcf951a2a4d'

export const bugReportBaseUrl =
  'https://docs.google.com/forms/d/e/1FAIpQLSd-yj_06z7fzLBbfHZJCoJaiI3xdqDwS5blfn35NJmT-rDEHg/viewform'

export const surveyBaseUrl =
  'https://docs.google.com/forms/d/e/1FAIpQLSdpe15TXd-hPLK_tz8vRTZixh21YYKPO-8kFXEoXtjxstCCkA/viewform'

export const releaseNoteUrl =
  'https://gv-zero-public.notion.site/c69495f752e948fcb5c6f545ac6b6716?v=c1b07b945e694fd393f3a93d4226ea92'

export const allowCameraAccessUrl =
  'https://gv-zero-public.notion.site/55b1513452004d6d8865747f28ab3e18'
