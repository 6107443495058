import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Bugsnag, { NotifiableError } from '@bugsnag/js'
import styles from './style.module.scss'
import {
  buttonText,
  fixButtonText,
  loadingModalText,
  titleText,
} from './messages'
import { RootState } from '../../store'
import AppHeader from '../../components/organisms/AppHeader'
import Typography from '../../components/atoms/Typography'
import useLabelValues from './hooks/useLabelValues'
import LabelValue from '../../components/molecules/LabelValue'
import Button from '../../components/atoms/Button'
import Space from '../../components/atoms/Space'
import {
  createAnswers,
  clearApiRequest,
} from '../../features/network/formsSlice'
import {
  inputSalesDataPath,
  topWithClearDataPath,
  registerConfirmationPath,
  messageConfirmationPath,
} from '../../routes/paths'
import useTenant from '../hooks/useTenant'
import useExpire from '../hooks/useExpire'
import ErrorModal from '../../components/organisms/ErrorModal'
import { AppError } from '../../AppTypes'
import LoadingModal from '../../components/organisms/LoadingModal'
import { postSubmit } from '../../features/network/submitSlice'
import { getConsistencyResultStatus } from '../../features/network/consistencyResultSlice'
import { ConsistencyResultEnum } from '../../features/services/api'
import { sleep } from '../../utils'
import { timeoutErrorAppError } from '../../features/network/common'
import { useTrainingMode } from '../hooks/useMode'
import {
  clearApiRequest as trainingClearApiRequest,
  finishTraining,
  postTrainingInput,
} from '../../features/mode/modeSlice'
import useDate from '../hooks/useDate'
import FullSize from '../../components/templates/FullSize'
import StepperHeader from '../../components/organisms/StepperHeader'
import useAppHistory from '../../utils/useAppHistory'

function InputSalesDataConfirm(): ReactElement {
  const [loopCount, setLoopCount] = useState(0)
  const [timeoutError, setTimeoutError] = useState<AppError | null>(null)
  const [isCreatedAnswer, setIsCreatedAnswer] = useState(false)
  const [isProgress, setInProgress] = useState(false)
  const tenant = useTenant()
  const { forms, answers, apiStatus, error: inputDataError } = useSelector(
    (state: RootState) => state.inputData
  )
  const {
    mode,
    apiStatus: trainingApiStatus,
    error: trainingError,
  } = useSelector((state: RootState) => state.mode)
  const { registerCount } = useSelector((state: RootState) => state.register)
  const { result, error: consistencyError } = useSelector(
    (state: RootState) => state.consistencyResult
  )
  const dispatch = useDispatch()
  const history = useAppHistory()
  const isTraining = useTrainingMode()
  const labelValues = useLabelValues(forms, answers)
  useExpire()

  const handleFixButtonClick = (): void => {
    history.push(inputSalesDataPath)
  }

  const handleErrorClick = (err: AppError): void => {
    if (err.needReturnTop) {
      history.push(topWithClearDataPath)
      return
    }
    if (isTraining) {
      dispatch(trainingClearApiRequest())
    } else {
      dispatch(clearApiRequest())
    }
    setInProgress(false)
  }

  const dispatchGetConsistencyResult = async (): Promise<void> => {
    await dispatch(getConsistencyResultStatus())
  }

  const handleButtonClick = async (): Promise<void> => {
    setInProgress(true)
    if (isTraining) {
      const actionResult = await dispatch(postTrainingInput(answers))
      if (
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        postTrainingInput.fulfilled.match(actionResult)
      ) {
        setIsCreatedAnswer(true)
      }
    } else {
      const actionResult = await dispatch(createAnswers(answers))
      if (
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        createAnswers.fulfilled.match(actionResult)
      ) {
        setIsCreatedAnswer(true)
        dispatchGetConsistencyResult()
      }
    }
  }

  const submit = async (): Promise<void> => {
    if (isTraining) {
      const finishResult = await dispatch(finishTraining())
      if (
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        finishTraining.fulfilled.match(finishResult)
      ) {
        history.push(messageConfirmationPath)
      }
    } else {
      const submitResult = await dispatch(postSubmit())
      if (
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        postSubmit.fulfilled.match(submitResult)
      ) {
        history.push(messageConfirmationPath)
      }
    }
  }

  const timeoutErrorBugsnagError = (
    storeCode: string,
    tenantCode: string
  ): NotifiableError => {
    return {
      name: 'Timeout Error',
      message: `Generation of Consistency Result could not be confirmed. code: ${storeCode}/${tenantCode}`,
    }
  }

  const goToNext = (): void => {
    if (process.env.REACT_APP_OIOI_MODULE) {
      submit()
    } else {
      history.push(registerConfirmationPath)
    }
  }

  useEffect(() => {
    if (!isCreatedAnswer) return

    // NOTE: 研修モード時は、整合性チェックの結果を待たずに次に進む
    if (isTraining) {
      goToNext()
      return
    }

    if (loopCount >= 10) {
      setInProgress(false)
      setTimeoutError(timeoutErrorAppError)
      Bugsnag.notify(
        timeoutErrorBugsnagError(tenant.storeCode, tenant.tenantCode)
      )
      return
    }

    if (result === ConsistencyResultEnum.InProgress) {
      sleep(2000).then(() => {
        setLoopCount(loopCount + 1)
        dispatchGetConsistencyResult()
      })
    } else {
      goToNext()
    }
  }, [result, loopCount, isCreatedAnswer])

  const isLoading =
    apiStatus === 'Progress' || trainingApiStatus === 'Progress' || isProgress

  const errorObject = {
    post: { error: inputDataError },
    timeout: { error: timeoutError },
    training: { error: trainingError },
    consistency: { error: consistencyError },
  }

  return (
    <FullSize className={styles.container} mode={mode} date={useDate()}>
      <StepperHeader current={2} />
      <AppHeader tenantName={tenant.tenantName} storeName={tenant.storeName} />
      <div className={styles.wrapper}>
        <Typography
          className={styles.title}
          variant="largeTitle"
          newLine
          align="left"
          color="primary"
        >
          {titleText(registerCount)}
        </Typography>
        <Space size="26px" orientation="vertical" />
        <div className={styles.labelValues}>
          {labelValues.length &&
            labelValues.map((lv) => (
              <LabelValue
                label={lv.label}
                value={lv.value}
                key={lv.id}
                className={styles.labelValue}
              />
            ))}
        </div>
        <Space orientation="vertical" size="42px" />
        <div className={styles.buttons}>
          <Button
            variant="contained"
            color="white"
            className={styles.button}
            onClick={handleFixButtonClick}
          >
            {fixButtonText}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={styles.button}
            onClick={handleButtonClick}
            disabled={isLoading}
          >
            {buttonText}
          </Button>
        </div>
        <Space size="42px" orientation="vertical" />
      </div>
      {isLoading && (
        <LoadingModal
          isOpen
          className={styles.loadingModal}
          text={loadingModalText}
        />
      )}
      {Object.entries(errorObject).map(
        ([key, { error }]) =>
          error && (
            <ErrorModal
              key={key}
              isOpen={Boolean(error)}
              title={error.title}
              description={error.description}
              buttonTitle="OK"
              onClick={(): void => handleErrorClick(error)}
            />
          )
      )}
    </FullSize>
  )
}

export default InputSalesDataConfirm
