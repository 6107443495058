import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { clearImage } from '../../features/camera/cameraSlice'
import { clearConsistencyStatements } from '../../features/network/consistencySlice'
import { clearConsistencyResult } from '../../features/network/consistencyResultSlice'
import { clearUploadImage } from '../../features/network/uploadImageSlice'
import { clearSalesDate } from '../../features/network/salesDateSlice'
import { clearTenantInfo } from '../../features/network/authSlice'
import { clearSubmitStatus } from '../../features/network/submitSlice'
import { clearAnswers } from '../../features/network/formsSlice'
import { clearMessage } from '../../features/network/messageSlice'
import { clearMode } from '../../features/mode/modeSlice'
import { clearRegisterCount } from '../../features/register/registerSlice'
import { persistor } from '../../store'
import { clearEmail } from '../../features/network/tenantUserSlice'
import { clearNotifications } from '../../features/network/notificationsSlice'
import { clearTenantRegisters } from '../../features/network/tenantRegisterSlice'

const clears = [
  clearImage,
  clearConsistencyStatements,
  clearConsistencyResult,
  clearUploadImage,
  clearSubmitStatus,
  clearSalesDate,
  clearTenantInfo,
  clearAnswers,
  clearMessage,
  clearMode,
  clearRegisterCount,
  clearEmail,
  clearNotifications,
  clearTenantRegisters,
]

export default (): void => {
  const dispatch = useDispatch()
  useEffect(() => {
    persistor.purge().then(() => {
      clears.forEach((c) => {
        dispatch(c())
      })
    })
  }, [])
}
