import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import styles from './style.module.scss'
import Icon from '../../components/atoms/Icon'
import Typography from '../../components/atoms/Typography'
import { titleText, buttonText, nextButtonText } from './messages'
import {
  inputSalesDataPath,
  registerConfirmationPath,
} from '../../routes/paths'
import useTenant from '../hooks/useTenant'
import useExpire from '../hooks/useExpire'
import TenantHeaderBottomButtonFullHeight from '../../components/templates/TenantHeaderBottomButtonFullHeight'
import { RootState } from '../../store'
import useDate from '../hooks/useDate'
import { useIsOpa } from '../hooks/useOrg'
import useMode, { useResentMode } from '../hooks/useMode'
import useAppHistory from '../../utils/useAppHistory'

function UploadCompletion(): ReactElement | null {
  const history = useAppHistory()
  const tenant = useTenant()
  const mode = useMode()
  const isResent = useResentMode()
  const isOPA = useIsOpa()
  const { registerCount } = useSelector((state: RootState) => state.register)
  const handleClick = (): void => {
    if (isOPA) {
      history.push(registerConfirmationPath)
    } else {
      history.push(inputSalesDataPath)
    }
  }
  useExpire()
  return (
    <TenantHeaderBottomButtonFullHeight
      tenant={tenant}
      buttonText={isOPA ? nextButtonText : buttonText}
      buttonColor={isOPA ? 'green' : 'primary'}
      className={styles.container}
      onClick={handleClick}
      mode={mode}
      date={useDate()}
      currentStep={isOPA && !isResent ? 3 : 2}
    >
      <Icon icon="rightPointing" className={styles.icon} />
      <Typography variant="largeTitle" align="center" className={styles.text}>
        {titleText(registerCount)}
      </Typography>
    </TenantHeaderBottomButtonFullHeight>
  )
}

export default UploadCompletion
