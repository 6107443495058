import React, { ReactElement } from 'react'
import styles from './style.module.scss'
import Typography from '../../components/atoms/Typography'
import {
  buttonTitle,
  captionNg,
  captionOk,
  guideNotes,
  ngPoints,
  okPoints,
  title,
} from './messages'
import CircleIndexOrderList from '../../components/molecules/CircleIndexOrderList'
import UnOrderList from '../../components/molecules/UnOrderList'
import Button from '../../components/atoms/Button'
import { genCameraPath } from '../../routes/paths'
import Space from '../../components/atoms/Space'
import okImage from './assets/ok.png'
import ngImage from './assets/ng.png'
import ImageWithCaption from '../../components/molecules/ImageWithCaption'
import StepperHeader from '../../components/organisms/StepperHeader'
import useAppHistory from '../../utils/useAppHistory'
import { useResentMode } from '../hooks/useMode'
import { useIsOpa } from '../hooks/useOrg'

function CameraGuide(): ReactElement {
  const history = useAppHistory()
  const isResent = useResentMode()
  const isOPA = useIsOpa()
  const handleClick = (): void => {
    history.push(genCameraPath(0))
  }
  return (
    <div className={styles.container}>
      <StepperHeader
        current={isOPA && !isResent ? 2 : 1}
        isOpaResent={isOPA && isResent}
      />
      <Typography className={styles.text} align="left" color="white" newLine>
        {title}
      </Typography>
      <Space size="24px" orientation="vertical" />
      <CircleIndexOrderList items={guideNotes} color="white" />
      <Space size="60px" orientation="vertical" />
      <ImageWithCaption
        src={okImage}
        alt="ok"
        caption={captionOk}
        color="white"
      />
      <UnOrderList items={okPoints} />
      <ImageWithCaption
        src={ngImage}
        alt="ng"
        caption={captionNg}
        color="white"
      />
      <UnOrderList items={ngPoints} />
      <Space size="70px" orientation="vertical" />
      <Button variant="contained" color="white" onClick={handleClick}>
        {buttonTitle}
      </Button>
    </div>
  )
}

export default CameraGuide
