import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TenantHeaderBottomButtonFullHeight from '../../components/templates/TenantHeaderBottomButtonFullHeight'
import useTenant from '../hooks/useTenant'
import styles from './style.module.scss'
import {
  title,
  description,
  placeholder,
  buttonText,
  secondButtonText,
} from './message'
import {
  inputMessageConfirmPath,
  messageConfirmationPath,
} from '../../routes/paths'
import Typography from '../../components/atoms/Typography'
import { updateMessage } from '../../features/network/messageSlice'
import { RootState } from '../../store'
import useDate from '../hooks/useDate'
import { useIsOpa } from '../hooks/useOrg'
import useMode, { useResentMode } from '../hooks/useMode'
import useAppHistory from '../../utils/useAppHistory'

function InputMessage(): ReactElement {
  const tenant = useTenant()
  const history = useAppHistory()
  const isResent = useResentMode()
  const isOPA = useIsOpa()
  const dispatch = useDispatch()

  const [message, setMessage] = useState('')
  const mode = useMode()

  const { body } = useSelector((state: RootState) => state.message)

  useEffect(() => {
    setMessage(body)
  }, [body])

  const handleChangeMessage = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    setMessage(e.target.value)
  }

  const handleClickNext = (): void => {
    dispatch(updateMessage(message))
    history.push(inputMessageConfirmPath)
  }

  const handleClickCancel = (): void => {
    history.push(messageConfirmationPath)
  }

  return (
    <TenantHeaderBottomButtonFullHeight
      className={styles.container}
      buttonText={buttonText}
      buttonColor="green"
      secondButtonText={secondButtonText}
      secondButtonColor="primary"
      secondButtonVariant="text"
      tenant={tenant}
      mode={mode}
      date={useDate()}
      onClick={handleClickNext}
      onClickSecond={handleClickCancel}
      disabledButton={!message}
      currentStep={
        process.env.REACT_APP_OIOI_MODULE || (isOPA && isResent) ? 3 : 4
      }
      isOpaResent={isOPA && isResent}
    >
      <Typography variant="largeTitle" align="center" bold>
        {title}
      </Typography>
      <Typography variant="description" align="center">
        {description}
      </Typography>
      <div className={styles.textareaWrapper}>
        <textarea
          className={styles.textarea}
          placeholder={placeholder}
          value={message}
          onChange={handleChangeMessage}
        />
      </div>
    </TenantHeaderBottomButtonFullHeight>
  )
}

export default InputMessage
