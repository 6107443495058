import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { bobaService } from '../services'
import { AppThunkConfig } from '../../store'
import {
  ApiError,
  AuthorizationError,
  OutOfServiceError,
} from '../services/errors'
import { ApiRequest, AppError, FormAnswerWrapper } from '../../AppTypes'
import { ErrorCodeEnum, FormAnswer } from '../services/api'
import {
  authenticationErrorAppError,
  authorizationErrorAppError,
  internalServerErrorAppError,
  outOfServiceAppErr,
} from '../network/common'
import { disableLeaveConfirmation, enableLeaveConfirmation } from '../../utils'

const service = bobaService()

export enum ModeEnum {
  Normal = 'normal',
  Training = 'training',
  Resent = 'resent',
}

type State = {
  mode: ModeEnum
} & ApiRequest

const initialState: State = {
  apiStatus: 'Initial',
  mode: ModeEnum.Normal,
  error: undefined,
}

const handleError = (err: unknown): AppError => {
  if (err instanceof OutOfServiceError) {
    return outOfServiceAppErr
  }
  if (err instanceof AuthorizationError) {
    return authenticationErrorAppError
  }
  return internalServerErrorAppError
}

const handlePutError = (err: unknown): AppError => {
  if (err instanceof ApiError) {
    switch (err.errorCode) {
      case ErrorCodeEnum.AuthorizationError:
        return authorizationErrorAppError
      case ErrorCodeEnum.InternalServerError:
        return internalServerErrorAppError
      default:
        return internalServerErrorAppError
    }
  }
  return handleError(err)
}

const handlePostError = (err: unknown): AppError => {
  if (err instanceof ApiError) {
    switch (err.errorCode) {
      case ErrorCodeEnum.InvalidInput:
        return {
          needReturnTop: false,
          description:
            '入力した売上値に不備があります。確認後に修正を行なってください。',
          title: '入力エラー',
        }
      case ErrorCodeEnum.AuthorizationError:
        return authorizationErrorAppError
      default:
        return internalServerErrorAppError
    }
  }
  return handleError(err)
}

export const finishTraining = createAsyncThunk<void, void, AppThunkConfig>(
  'training/finish',
  async (_, { getState, rejectWithValue }) => {
    try {
      return await service.putTrainingSubmit(getState())
    } catch (err) {
      return rejectWithValue(handlePutError(err))
    }
  }
)

export const postTrainingInput = createAsyncThunk<
  void,
  FormAnswerWrapper[],
  AppThunkConfig
>('training/input', async (args, { getState, rejectWithValue }) => {
  try {
    return await service.postTrainingInputForm(
      {
        answers: args.map(
          (a): FormAnswer => ({
            formId: a.formId,
            value: a.value,
          })
        ),
      },
      getState()
    )
  } catch (err) {
    return rejectWithValue(handlePostError(err))
  }
})

const trainingSlice = createSlice({
  name: 'training',
  initialState,
  reducers: {
    clearApiRequest: (state): State => {
      return {
        ...state,
        apiStatus: 'Initial',
        error: undefined,
      }
    },
    clearMode: (): State => {
      return initialState
    },
    enableTrainingMode: (state): State => {
      return { ...state, mode: ModeEnum.Training }
    },
    enableResentMode: (state): State => {
      return { ...state, mode: ModeEnum.Resent }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      finishTraining.pending,
      (state): State => {
        return {
          ...state,
          apiStatus: 'Progress',
        }
      }
    )
    builder.addCase(
      finishTraining.fulfilled,
      (state): State => {
        return {
          ...state,
          apiStatus: 'Success',
        }
      }
    )
    builder.addCase(
      finishTraining.rejected,
      (state, action): State => {
        return {
          ...state,
          apiStatus: 'Failure',
          error: action.payload,
        }
      }
    )
    builder.addCase(postTrainingInput.pending, (state) => {
      enableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Progress',
      }
    })
    builder.addCase(postTrainingInput.fulfilled, (state) => {
      disableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Success',
      }
    })
    builder.addCase(postTrainingInput.rejected, (state, action) => {
      disableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Failure',
        error: action.payload,
      }
    })
  },
})

export const {
  clearApiRequest,
  clearMode,
  enableTrainingMode,
  enableResentMode,
} = trainingSlice.actions

export default trainingSlice
