export const titleText = (count: number): string => {
  const base = `レシートが
アップロードされました！`
  if (count > 1) {
    return `${count}台目レジの${base}`
  }
  return base
}
export const buttonText = `売上入力へすすむ`
export const nextButtonText = '次へ'
