import React, { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { buttonText, description, title, secondButtonText } from './messages'
import styles from './style.module.scss'
import LargeTitleDescription from '../../components/molecules/LargeTitleDescription'
import { RootState } from '../../store'
import {
  registerConfirmationPath,
  topPath,
  genCameraPath,
  topWithClearDataPath,
} from '../../routes/paths'
import {
  postAppSalesdateRegisters,
  decrementRegisterCount,
} from '../../features/register/registerSlice'
import useExpire from '../hooks/useExpire'
import TenantHeaderBottomButtonFullHeight from '../../components/templates/TenantHeaderBottomButtonFullHeight'
import ErrorModal from '../../components/organisms/ErrorModal'
import { AppError } from '../../AppTypes'
import useMode, { useTrainingMode, useResentMode } from '../hooks/useMode'
import useDate from '../hooks/useDate'
import { useIsOpa } from '../hooks/useOrg'
import useAppHistory from '../../utils/useAppHistory'

function PreCamera(): ReactElement {
  const history = useAppHistory()
  const mode = useMode()
  const isTraining = useTrainingMode()
  const isResent = useResentMode()
  const isOPA = useIsOpa()
  const dispatch = useDispatch()
  const { salesDateAsNumber } = useSelector(
    (state: RootState) => state.salesDate
  )
  const { registerCount } = useSelector((state: RootState) => state.register)
  const { error } = useSelector((state: RootState) => state.auth)
  const [isDisabled, setIsDisabled] = useState(false)

  if (!salesDateAsNumber) {
    history.push(topPath)
  }

  useExpire()

  const handleClick = async (): Promise<void> => {
    setIsDisabled(true)
    if (isTraining) {
      history.push(genCameraPath(0))
      return
    }

    const postResult = await dispatch(postAppSalesdateRegisters())
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    if (postAppSalesdateRegisters.fulfilled.match(postResult)) {
      history.push(genCameraPath(0))
    }
    setIsDisabled(false)
  }

  const handleClickSecond = (): void => {
    dispatch(decrementRegisterCount())
    history.push(registerConfirmationPath)
  }

  const handleErrorModalButtonClicked = (appError: AppError): void => {
    if (appError.needReturnTop) {
      history.push(topWithClearDataPath)
    } else {
      handleClick()
    }
  }

  return (
    // TODO: second button のデザイン変更
    <TenantHeaderBottomButtonFullHeight
      buttonText={buttonText}
      secondButtonText={secondButtonText}
      tenant={null}
      className={styles.container}
      secondButtonColor="primary"
      secondButtonVariant="text"
      mode={mode}
      date={useDate()}
      onClick={handleClick}
      onClickSecond={handleClickSecond}
      currentStep={isOPA && !isResent ? 2 : 1}
      disabledButton={isDisabled}
    >
      {error && (
        <ErrorModal
          isOpen={Boolean(error)}
          title={error.title}
          description={error.description}
          buttonTitle="OK"
          onClick={(): void => handleErrorModalButtonClicked(error)}
        />
      )}
      <LargeTitleDescription
        title={title(new Date(salesDateAsNumber), registerCount)}
        description={description}
        className={styles.txt}
        color="primary"
      />
    </TenantHeaderBottomButtonFullHeight>
  )
}

export default PreCamera
