import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { RootState } from '../store'

const useBigQuery = (): {
  sendEvent: (path: string) => Promise<void>
} => {
  const { orgCode, tenant } = useSelector((state: RootState) => state.auth)
  const { salesDateAsNumber } = useSelector(
    (state: RootState) => state.salesDate
  )
  const { mode } = useSelector((state: RootState) => state.mode)

  const salesDate = salesDateAsNumber
    ? dayjs(salesDateAsNumber).format('YYYY-MM-DD')
    : undefined

  const sendEvent = async (path: string): Promise<void> => {
    // see:
    // production: https://console.firebase.google.com/u/0/project/boba-production/functions?hl=ja
    // staging   : https://console.firebase.google.com/u/0/project/boba-staging/functions?hl=ja
    const url =
      process.env.REACT_APP_ENV === 'production'
        ? 'https://sendevent-33p5c7iy7q-uc.a.run.app'
        : 'https://sendevent-wrbw6qzaqa-uc.a.run.app'
    const params = {
      tenantId: tenant?.id,
      orgCode,
      storeCode: tenant?.storeCode,
      tenantCode: tenant?.tenantCode,
      path,
      reportMode: mode,
      salesDate,
    }

    try {
      await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      })
    } catch {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.error('failed to send event')
      }
    }
  }

  return { sendEvent }
}

export { useBigQuery }
