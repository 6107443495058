export const outOfServiceAppErr = {
  needReturnTop: true,
  description: `利用時間外もしくはメンテナンス中です。トップに戻ります。`,
  title: 'エラー',
}

export const internalServerErrorAppError = {
  needReturnTop: false,
  description: `サーバーでエラーが発生しました。時間をおいて再度お試しください。
解決しない場合はお問い合わせください。`,
  title: 'サーバーエラー',
  isInternalServerError: true,
}

export const authenticationErrorAppError = {
  needReturnTop: true,
  description: `認証に失敗しました。お手数ですが再度認証してからアクセスしてください。`,
  title: '認証エラー',
}

export const authorizationErrorAppError = {
  needReturnTop: true,
  description:
    '所定の時間が経過したので、無効になりました。初めからお試しください。',
  title: '',
}

export const timeoutErrorAppError = {
  title: 'タイムアウトエラー',
  description: `通信に時間がかかりすぎて処理が完了しませんでした。
  お手数ですが、最初からやり直してください。`,
  needReturnTop: true,
}
