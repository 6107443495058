import React, { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnimationControls, motion, useAnimation } from 'framer-motion'
import styles from './style.module.scss'
import Frame from '../../components/atoms/Frame'
import Typography from '../../components/atoms/Typography'
import {
  notionText,
  confirmTenantText,
  okButton,
  recapture,
  reAuth,
  cameraAuthErrorTitle,
  cameraAuthErrorButton,
  goToTop,
} from './messages'
import Icon from '../../components/atoms/Icon'
import QRCodeCameraVideo from '../../components/molecules/QRCodeCameraVideo'
import { RootState } from '../../store'
import TenantNameIcon from '../../components/organisms/TenantNameIcon'
import Button from '../../components/atoms/Button'
import LoadingModal from '../../components/organisms/LoadingModal'
import {
  topPath,
  topWithClearDataPath,
  reportCommandPath,
} from '../../routes/paths'
import useExpire from '../hooks/useExpire'
import { Tenant } from '../../features/services/api'
import ErrorModal from '../../components/organisms/ErrorModal'
import Div100vh from '../../components/atoms/Div100vh'
import {
  clearTenantInfo,
  tenantAuth,
  recursiveScan,
} from '../../features/network/authSlice'
import useRePromptVideo from '../../utils/useRePromptVideo'
import { allowCameraAccessUrl } from '../../constants'
import useAppHistory from '../../utils/useAppHistory'
import { enableOpenModal } from '../../features/network/notificationsSlice'

type Props = {}

const VIDEO_ID = 'reader-video'

const animationVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
}

const renderDefault = (
  visible: boolean,
  handleScanned: (r: string) => Promise<void>,
  handleBackClick: () => void
): ReactElement => {
  return (
    <motion.div
      className={styles.frameContainer}
      initial="visible"
      animate={visible ? 'visible' : 'hidden'}
      variants={animationVariants}
    >
      <div className={styles.frames}>
        <Frame className={styles.frame} />
        <div className={styles.space} />
        <Frame className={styles.frame} />
      </div>
      <div className={styles.footer}>
        <div className={styles.note}>
          <Typography variant="span" color="white" className={styles.text}>
            {notionText}
          </Typography>
        </div>
        <Button
          className={styles.auth}
          variant="rounded"
          color="white"
          onClick={(): Promise<void> => useRePromptVideo(VIDEO_ID)}
        >
          {reAuth}
        </Button>
      </div>
      {process.env.NODE_ENV !== 'production' && (
        <button
          type="button"
          style={{ position: 'relative' }}
          onClick={(): void => {
            handleScanned('0010010010332')
          }}
        >
          debug
        </button>
      )}
      <Icon
        icon="close"
        onClick={handleBackClick}
        color="white"
        className={styles.closeIcon}
      />
    </motion.div>
  )
}

const renderFoundTenant = (
  tenant: Tenant,
  controls: AnimationControls,
  retakeButtonTitle: string,
  handleOkButtonClicked: () => void,
  handleRetakeOrReEnterButtonClicked: () => void
): ReactElement => {
  return (
    <motion.div
      className={styles.modalContainer}
      initial="hidden"
      animate={tenant ? 'visible' : controls}
      variants={animationVariants}
    >
      <div />
      <Typography className={styles.confirmText} color="white">
        {confirmTenantText}
      </Typography>
      <TenantNameIcon
        className={styles.shopName}
        tenantName={`${tenant.tenantName} 様`}
        shopName={tenant.storeName}
      />
      <Button
        variant="contained"
        className={styles.button}
        color="green"
        onClick={handleOkButtonClicked}
        bold
      >
        {okButton}
      </Button>
      <Button
        variant="text"
        color="white"
        onClick={handleRetakeOrReEnterButtonClicked}
      >
        {retakeButtonTitle}
      </Button>
      <div />
    </motion.div>
  )
}

const Reader: React.FC<Props> = () => {
  const history = useAppHistory()
  const dispatch = useDispatch()
  const controls = useAnimation()
  useExpire()
  const { store, tenant, apiStatus, error } = useSelector(
    (state: RootState) => state.auth
  )
  const [showPermissionError, setPermissionShowError] = useState(false)
  const showError = Boolean(error || showPermissionError)

  const [scanEnabled, setScanEnabled] = useState(true)
  const isLoading = apiStatus === 'Progress'

  const handleScanned = async (result: string): Promise<void> => {
    if (result.includes(window.location.hostname)) {
      setScanEnabled(false)

      dispatch(recursiveScan())
    } else if (result) {
      setScanEnabled(false)
      const actionResult = await dispatch(tenantAuth(result))
      // 行くはずなんだけど引っかかってしまう。
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      if (tenantAuth.fulfilled.match(actionResult)) {
        await controls.start('visible')
      }
    }
  }

  const handleRetakeOrReEnterButtonClicked = (): void => {
    dispatch(clearTenantInfo())
    setScanEnabled(true)
  }
  const handleOkButtonClicked = (): void => {
    dispatch(enableOpenModal())
    history.push(reportCommandPath)
  }
  const handleBackClicked = (): void => {
    history.push(topPath)
  }
  const handleErrorModalButtonClicked = (): void => {
    if (!error) return
    if (error.needReturnTop) {
      history.push(topWithClearDataPath)
    } else {
      handleRetakeOrReEnterButtonClicked()
    }
  }

  const handleCameraError = (): void => {
    setPermissionShowError(true)
  }
  const handleClickHowToEnableCamera = (): void => {
    window.open(allowCameraAccessUrl)
  }

  // アニメーションつけ途中だけど諦めて後ほど
  return (
    <Div100vh disabledModeWrapper>
      <motion.div
        className={styles.container}
        initial={{ scale: 0.4 }}
        animate={{ scale: 1.0 }}
        data-testid="reader-page"
      >
        <QRCodeCameraVideo
          videoId={VIDEO_ID}
          className={styles.video}
          onScanned={handleScanned}
          onCameraAuthError={handleCameraError}
          scanEnabled={scanEnabled && !isLoading && !(tenant || store)}
        />
        {error && (
          <ErrorModal
            isOpen={Boolean(error)}
            title={error.title}
            description={error.description}
            buttonTitle="ok"
            onClick={handleErrorModalButtonClicked}
          />
        )}
        {showPermissionError && (
          <ErrorModal
            isOpen={showPermissionError}
            title={cameraAuthErrorTitle}
            buttonTitle={cameraAuthErrorButton}
            buttonTitleSecondary={goToTop}
            onClick={handleClickHowToEnableCamera}
            onClickSecondary={(): void => history.push(topPath)}
          />
        )}
        {isLoading && (
          <LoadingModal isOpen={isLoading} className={styles.loadingModal} />
        )}
        {!isLoading && !showError && (
          <>
            {!tenant
              ? renderDefault(!tenant, handleScanned, handleBackClicked)
              : renderFoundTenant(
                  tenant,
                  controls,
                  recapture,
                  handleOkButtonClicked,
                  handleRetakeOrReEnterButtonClicked
                )}
          </>
        )}
      </motion.div>
    </Div100vh>
  )
}

export default Reader
