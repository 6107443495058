import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ApiRequest, AppError } from '../../AppTypes'
import { bobaService } from '../services'
import { AppThunkConfig } from '../../store'
import { enableLeaveConfirmation } from '../../utils'
import { ErrorCodeEnum } from '../services/api'
import {
  ApiError,
  OutOfServiceError,
  AuthorizationError,
} from '../services/errors'
import {
  authorizationErrorAppError,
  internalServerErrorAppError,
  outOfServiceAppErr,
  authenticationErrorAppError,
} from './common'

const service = bobaService()

type State = {
  email: string
} & ApiRequest

const initialState: State = {
  email: '',
  apiStatus: 'Initial',
  error: undefined,
}

// Error Handling

export type tenantUserSignUpParam = {
  email: string
}

const handleError = (err: Error): AppError => {
  // FIX: 文言もカスタムする
  if (err instanceof ApiError) {
    switch (err.errorCode) {
      case ErrorCodeEnum.AuthorizationError:
        return authorizationErrorAppError
      default:
        return internalServerErrorAppError
    }
  }
  if (err instanceof OutOfServiceError) {
    return outOfServiceAppErr
  }
  if (err instanceof AuthorizationError) {
    return authenticationErrorAppError
  }
  return internalServerErrorAppError
}

export const tenantUserSignUp = createAsyncThunk<
  void,
  tenantUserSignUpParam,
  AppThunkConfig
>('/app/sign-up', async (args, { getState, rejectWithValue }) => {
  try {
    return await service.tenantUserSignUp(
      {
        email: args.email,
      },
      getState()
    )
  } catch (err) {
    return rejectWithValue(handleError(err))
  }
})

const tenantUserSlice = createSlice({
  name: 'tenantUser',
  initialState,
  reducers: {
    clearApiRequest: (state: State): State => {
      return {
        ...state,
        apiStatus: 'Initial',
        error: undefined,
      }
    },
    updateEmail: (state: State, { payload }: PayloadAction<string>): State => {
      return {
        ...state,
        email: payload,
      }
    },
    clearEmail: (): State => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(tenantUserSignUp.pending, (state) => {
      enableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Progress',
      }
    })
    builder.addCase(tenantUserSignUp.fulfilled, (state) => {
      enableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Success',
      }
    })
    builder.addCase(tenantUserSignUp.rejected, (state, action) => {
      enableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Failure',
        error: action.payload,
      }
    })
  },
})

export const {
  clearApiRequest,
  updateEmail,
  clearEmail,
} = tenantUserSlice.actions
export default tenantUserSlice
