import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ApiRequest, AppError } from '../../AppTypes'
import { AppThunkConfig } from '../../store'
import { enableLeaveConfirmation } from '../../utils'
import { bobaService } from '../services'
import { ErrorCodeEnum } from '../services/api'
import {
  ApiError,
  AuthorizationError,
  OutOfServiceError,
} from '../services/errors'
import {
  authenticationErrorAppError,
  authorizationErrorAppError,
  internalServerErrorAppError,
  outOfServiceAppErr,
} from './common'

const service = bobaService()

type State = {
  body: string
} & ApiRequest

const initialState: State = {
  body: '',
  apiStatus: 'Initial',
  error: undefined,
}

const handlerError = (err: unknown): AppError => {
  if (err instanceof ApiError) {
    switch (err.errorCode) {
      case ErrorCodeEnum.InvalidInput:
        return {
          needReturnTop: false,
          description:
            '入力内容に不備があります。確認後に修正を行なってください。',
          title: '入力エラー',
        }
      case ErrorCodeEnum.AuthorizationError:
      case ErrorCodeEnum.InvalidToken:
        return authorizationErrorAppError
      case ErrorCodeEnum.InternalServerError:
        return internalServerErrorAppError
      default:
        return {
          needReturnTop: false,
          description:
            '補足事項の登録中にエラーが発生しました。時間をおいて再度お試しください。',
          title: '売上登録エラー',
        }
    }
  } else if (err instanceof OutOfServiceError) {
    return outOfServiceAppErr
  } else if (err instanceof AuthorizationError) {
    return authenticationErrorAppError
  }
  return internalServerErrorAppError
}

export type PutMessageParam = {
  message: string
}

export const putMessage = createAsyncThunk<
  void,
  PutMessageParam,
  AppThunkConfig
>('message/put', async (args, { getState, rejectWithValue }) => {
  try {
    return await service.putSalesReportMessage(
      {
        message: args.message,
      },
      getState()
    )
  } catch (err) {
    return rejectWithValue(handlerError(err))
  }
})

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    clearApiRequest: (state: State): State => {
      return {
        ...state,
        apiStatus: 'Initial',
        error: undefined,
      }
    },
    clearMessage: (): State => {
      return initialState
    },
    updateMessage: (
      state: State,
      { payload }: PayloadAction<string>
    ): State => {
      return {
        ...state,
        body: payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(putMessage.pending, (state) => {
      enableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Progress',
      }
    })
    builder.addCase(putMessage.fulfilled, (state) => {
      enableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Success',
      }
    })
    builder.addCase(putMessage.rejected, (state, action) => {
      enableLeaveConfirmation()
      return {
        ...state,
        apiStatus: 'Failure',
        error: action.payload,
      }
    })
  },
})

export const {
  clearApiRequest,
  clearMessage,
  updateMessage,
} = messageSlice.actions
export default messageSlice
