import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AppError, Status } from '../../AppTypes'
import { AppThunkConfig } from '../../store'
import { bobaService } from '../services'
import { ErrorCodeEnum, TenantRegister } from '../services/api'
import {
  OutOfServiceError,
  AuthorizationError,
  ApiError,
} from '../services/errors'
import {
  outOfServiceAppErr,
  authenticationErrorAppError,
  internalServerErrorAppError,
  authorizationErrorAppError,
} from './common'
import { disableLeaveConfirmation, enableLeaveConfirmation } from '../../utils'

const service = bobaService()

type TenantRegisterState = {
  getApiStatus: Status
  postApiStatus: Status
  tenantRegisters: TenantRegister[]
  alreadyReportedTenantRegisters: TenantRegister[]
  getError?: AppError
  postError?: AppError
}

const initialState: TenantRegisterState = {
  getApiStatus: 'Initial',
  postApiStatus: 'Initial',
  tenantRegisters: [],
  alreadyReportedTenantRegisters: [],
  getError: undefined,
  postError: undefined,
}

const handleError = (err: Error): AppError => {
  // FIX: 文言もカスタムする
  if (err instanceof ApiError) {
    switch (err.errorCode) {
      case ErrorCodeEnum.AuthorizationError:
        return authorizationErrorAppError
      default:
        return internalServerErrorAppError
    }
  }
  if (err instanceof OutOfServiceError) {
    return outOfServiceAppErr
  }
  if (err instanceof AuthorizationError) {
    return authenticationErrorAppError
  }
  return internalServerErrorAppError
}

export const getTenantRegisterNumbers = createAsyncThunk<
  TenantRegister[],
  string,
  AppThunkConfig
>(
  'tenants/{tenant_id}/tenant-register-numbers',
  async (args, { getState, rejectWithValue }) => {
    try {
      return await service.getTenantRegisterNumbers(args, getState())
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)

export const postTenantRegisterAssignment = createAsyncThunk<
  void,
  string,
  AppThunkConfig
>(
  '/tenant-register-assignment',
  async (args, { getState, rejectWithValue }) => {
    try {
      return await service.postTenantRegisterAssignment(args, getState())
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)

const tenantRegisterSlice = createSlice({
  name: 'tenantRegister',
  initialState,
  reducers: {
    clearTenantRegisters: (): TenantRegisterState => {
      return {
        ...initialState,
      }
    },
    clearAPIStatus: (state): TenantRegisterState => {
      return {
        ...state,
        getApiStatus: 'Initial',
        postApiStatus: 'Initial',
        getError: undefined,
        postError: undefined,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTenantRegisterNumbers.pending, (state) => {
      enableLeaveConfirmation()
      return { ...state, getApiStatus: 'Progress' }
    })
    builder.addCase(getTenantRegisterNumbers.fulfilled, (state, action) => {
      disableLeaveConfirmation()
      const tenantRegisters = action.payload
      return {
        ...state,
        getApiStatus: 'Success',
        tenantRegisters,
      }
    })
    builder.addCase(getTenantRegisterNumbers.rejected, (state, action) => {
      disableLeaveConfirmation()
      return {
        ...state,
        getApiStatus: 'Failure',
        getError: action.payload,
      }
    })
    builder.addCase(postTenantRegisterAssignment.pending, (state) => {
      enableLeaveConfirmation()
      return { ...state, postApiStatus: 'Progress' }
    })
    builder.addCase(postTenantRegisterAssignment.fulfilled, (state, action) => {
      disableLeaveConfirmation()
      const reportedRegister = state.tenantRegisters.find(
        (register) => register.id === action.meta.arg
      )
      if (!reportedRegister) {
        return state
      }
      return {
        ...state,
        alreadyReportedTenantRegisters: [
          ...state.alreadyReportedTenantRegisters,
          reportedRegister,
        ],
        postApiStatus: 'Success',
      }
    })
    builder.addCase(postTenantRegisterAssignment.rejected, (state, action) => {
      disableLeaveConfirmation()
      return {
        ...state,
        postError: action.payload,
      }
    })
  },
})

export const {
  clearTenantRegisters,
  clearAPIStatus,
} = tenantRegisterSlice.actions

export default tenantRegisterSlice
