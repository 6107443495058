import React from 'react'
import clsx from 'clsx'
import ReactModal from 'react-modal'
import styles from './styles.module.scss'
import Typography from '../../atoms/Typography'
import Button from '../../atoms/Button'
import IconWithButton from '../../molecules/IconWithButton'
import BgWrapper from '../../atoms/BgWrapper'

type NotificationModalProps = {
  isOpen: boolean
  onClickConfirm: () => void
  onClickClose: () => void
}
type Props = React.StyledProps & NotificationModalProps
const NotificationModal: React.StyledFC<Props> = ({
  className,
  isOpen,
  onClickConfirm,
  onClickClose,
}: Props) => {
  return (
    <ReactModal
      isOpen={isOpen}
      className={clsx(className, styles.container)}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      overlayClassName={styles.overlay}
      onAfterOpen={(): void => {
        document.body.style.overflow = 'hidden'
      }}
      onAfterClose={(): void => {
        document.body.style.overflow = 'unset'
      }}
    >
      <BgWrapper
        color="white"
        variant="rounded"
        className={clsx(styles.bg, styles.modal)}
      >
        <div className={styles.content}>
          <Typography className={styles.title}>お知らせ</Typography>
          <Typography className={styles.description}>
            未読のメッセージがあります
          </Typography>
        </div>
        <div className={styles.buttons}>
          <Button
            onClick={onClickConfirm}
            color="white"
            variant="contained"
            className={clsx(styles.button, styles.positive)}
          >
            メッセージを確認する
          </Button>
          <IconWithButton
            icon="close"
            text="閉じる"
            buttonColor="white"
            color="primary"
            variant="text"
            onClick={onClickClose}
          />
        </div>
      </BgWrapper>
    </ReactModal>
  )
}

export default NotificationModal
