import React from 'react'
import clsx from 'clsx'
import styles from './style.module.scss'
import Stepper from '../../molecules/Stepper'
import { lastLabel } from './message'

type Props = {
  current: number
  isOpaResent?: boolean
} & React.StyledProps

const StepperHeader: React.StyledFC<Props> = ({
  style,
  current,
  className,
  isOpaResent = false,
}: Props) => {
  const count = process.env.REACT_APP_OIOI_MODULE || isOpaResent ? 4 : 5

  return (
    <Stepper
      className={clsx(className, styles.root)}
      {...{ style, count, current, lastLabel }}
    />
  )
}

export default StepperHeader
