import { useState, useEffect } from 'react'

type CameraStartError = Error | null

export default (
  requestedMedia: MediaStreamConstraints
): [MediaStream | null, Error | null] => {
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null)
  const [cameraStartError, setCameraStartError] = useState<CameraStartError>(
    null
  )
  useEffect((): void | (() => void | undefined) => {
    let unmounted = false
    const enableStream = async (): Promise<void> => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(requestedMedia)
        if (!unmounted) {
          setMediaStream(stream)
        }
      } catch (err) {
        navigator.permissions
          .query({ name: 'camera' })
          .then((permissionStatus) => {
            if (permissionStatus.state === 'denied') {
              setCameraStartError(new Error('NotAllowedError'))
            }
          })
        setCameraStartError(err)
      }
    }
    if (mediaStream) {
      return (): void => {
        unmounted = true
        mediaStream.getTracks().forEach((track) => {
          track.stop()
        })
      }
    }
    enableStream()
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return (): void => {}
  }, [mediaStream, requestedMedia])
  return [mediaStream, cameraStartError]
}
